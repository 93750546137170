import { useEffect, useState } from "react";
import Slider from "react-slick";
import SearchBar from "../../../components/searchbar";
import styles from '../styles/home.module.css';
import { BarIcon, ESIMMap, LocationIcon } from "../../../assets/images";
import { BundleCard } from "../../../components";
import { BUNDLE_TYPE } from "../../../utils/constants";
import { getBundles } from "../../esim/api";
import { Bundle } from "../../esim/interface";
import MainLoader from "../../../components/mainLoader";
import { bundleSearch, getCoupons } from "../api";
import { useSelector } from "react-redux";
import WelcomeBanner from "../../../components/modals/WelcomeBanner";
import users from "../../../assets/images/users.png"
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import MapComponent from "./MapComponent";
import { getCountryCenterCoordinates } from "../../../utils/getGeoCountryName";
import GeoChart from "./geoChart";

interface Coordinates {
    latitude: number;
    longitude: number;
    name: string;
}

const EsimDestination: React.FC = () => {
    const navigate = useNavigate();
    const [activeTab, setActiveTab] = useState<number>(BUNDLE_TYPE.LOCAL);
    const [bundles, setBundles] = useState<Bundle[]>([]);
    const [loader, setLoader] = useState<boolean>(true);
    const currency = useSelector(((state: any) => state?.getCurrency?.currency))
    const [welcomeModalShow, setWelcomeModalShow] = useState("");
    const [coupons, setCoupons] = useState([]);

    const [country, setCountry] = useState<string>('India');
    const [coordinates, setCoordinates] = useState<any>({
        latitude: "",
        longitude: "",
        name: "",
        countryCode: ""
    });


    const showWelcomeModalShow = (val: any) => {
        setWelcomeModalShow(val)
    }

    useEffect(() => {
        let params = {
            regionType: activeTab,
            limit: 1000,
        };
        setLoader(true)
        getBundles(params).then(res => {
            setBundles(res.data.bundles);
            setCountry(!res.data.bundles[0]?.name ? "India" : res.data.bundles[0]?.name)
            // setLoader(false)
        });

    }, [activeTab, currency]);


    useEffect(() => {
        let currentTime: any = new Date();

        const hasPopupBeenShown = localStorage.getItem('showWelcomeModal');

        if (!hasPopupBeenShown || currentTime.getTime() > hasPopupBeenShown) {

            currentTime.setHours(currentTime.getHours() + 12);

            localStorage.setItem('showWelcomeModal', currentTime.getTime());

            showWelcomeModalShow('show');
        }

        // getCoupons().then((response: any) => {
        //     setCoupons(response?.data)
        //     setLoader(false)
        // }).catch((error) => {
        //     setLoader(false)
        //     console.log(error)
        // })

        getCountryCenterCoordinates(country).then((response: any) => {
            setCoordinates(response);
            setLoader(false)
        }).catch((error) => {
            console.log(error)
            setLoader(false)
        });
    }, [country]);



    const handleSearchSubmit = (event: any) => {
        event.preventDefault();
        const searchValue = event?.target?.value;
        bundleSearch(searchValue).then((res: any) => {
            setBundles(res.data.bundles);
            setCountry(!searchValue ? res.data.bundles[0]?.name : !res.data.bundles[0]?.name ? "India" : res.data.bundles[0]?.name)
        });
        // onSubmit(searchValue); // Call the onSubmit prop function with the search value
    };


    return (
        <>
            <section className={styles.esimDestination}>
                {loader && <MainLoader />}
                <div className="container ipdad-cutm">
                    <h6>Connect Anywhere, Anytime – Your eSIM, Your Way</h6>
                    <h3>International eSIM Destinations</h3>
                    <div className={styles.esimBlogs}>
                        <ul>
                            <li onClick={() => {
                                setActiveTab(BUNDLE_TYPE.LOCAL)
                                setLoader(true)
                            }} className={activeTab === BUNDLE_TYPE.LOCAL ? styles.active : ''}>
                                <span>Local eSIM</span>
                            </li>
                            <li onClick={() => {
                                setActiveTab(BUNDLE_TYPE.MULTIVERSE)
                                setLoader(true)
                            }} className={activeTab === BUNDLE_TYPE.MULTIVERSE ? styles.active : ''}>
                                <span>MultiCountry</span>
                            </li>
                        </ul>
                        <div className={styles.esimPack}>
                            <div className="row">
                                <div className="col-md-4">
                                    <div className={styles.esimpackLeft}>
                                        <SearchBar searchClass={styles.topSearch} placeHolder={"Search Country, Regions, eSIM"} handleSearchSubmit={handleSearchSubmit} />
                                        <div className={styles.PackList}>
                                            <div className="homepckCard">
                                                {bundles?.length > 0 ? bundles.map(bundle => <BundleCard bundle={bundle} />) : <span style={{ color: '#fff' }}>Country or Regions not found</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8">
                                    <div className={styles.esimpackRight}>
                                        {/* <GeoChart coordinates={coordinates} /> */}
                                        {/* <MapComponent latitude={coordinates.latitude} longitude={coordinates.longitude} name={coordinates.name} /> */}
                                        <div className={styles.topFilter}>
                                            <span><img src={LocationIcon} alt="" /></span>
                                            <span><img src={BarIcon} alt="" /></span>
                                        </div>
                                        <div className={styles.mapLoc}>
                                            <img src={ESIMMap} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {welcomeModalShow && <WelcomeBanner show={welcomeModalShow} handleCloseModal={showWelcomeModalShow} />}
            </section>
        </>
    )
}

export default EsimDestination