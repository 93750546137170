import { API_URL } from '../../../config';
import { axios } from '../../../lib/axios';
import { RegionsResponse, CountriesResponse, BundlesResponse } from '../interface';

const getRegions = async (): Promise<RegionsResponse> => {
    return await axios.get(`${API_URL}/regions`);
}

const getCountries = async (): Promise<CountriesResponse> => {
    return await axios.get(`${API_URL}/regions/countries?limit=300`);
}

const getBundles = async (params: any) : Promise<BundlesResponse>=> {
    return await axios.get(`${API_URL}/bundle`, { params: params });
}
const getCoupons = async (params: any) : Promise<BundlesResponse>=> {
    return await axios.get(`${API_URL}/coupons`, { params: params });
}
const getBundleFilterData = async (params: any) : Promise<BundlesResponse>=> {
    return await axios.get(`${API_URL}/bundle/filter`, { params: params });
}

export {
    getRegions,
    getCountries,
    getBundles,
    getBundleFilterData
};