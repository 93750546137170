import Slider from 'react-slick';
import { useEffect, useState } from "react";
import MarkdownIt from "markdown-it";
// Components
import Layout from "../../../components/layout";
import HomeBanner from './HomeBanner';
import EsimDestination from './EsimDestination';
import { PartnerSection } from '../../../components';
import BlogSections from "./BlogSections";
import scone from "../../../assets/images/scone.png"
import sctwo from "../../../assets/images/sctwo.png"
import scthree from "../../../assets/images/scthree.png"

// Styles
import styles from "../styles/home.module.css";

// Images
import { getFAQS } from '../../faq/api';
import { FAQ } from '../../faq/interfaces';
import { getBundleByLocation } from '../api';
import { GetGeoLoactions } from '../../../utils/GetGeoLocation';
import MapComponent from './MapComponent';
import CountryCoordinates from './getCood';
import GeoChart from './geoChart';


const Home = () => {
    const [nav1, setNav1] = useState<any>();
    const [nav2, setNav2] = useState<any>();
    const [faqs, setFaqs] = useState<FAQ[]>([]);
    const [activeFAQ, setActiveFAQ] = useState<string>('');
    const [bannerEsim, setBannerEsim] = useState<any>();
    const [loader, setLoader] = useState(true);

    const mdParser = new MarkdownIt({
        html: true,
        linkify: true,
        typographer: true,
    });


    useEffect(() => {
        getFAQS().then((res) => {
            const faqs = res.data.faqs.slice(0, 3);
            setFaqs(faqs);
        }).catch((error) => {
            console.log(error)
        });

        GetGeoLoactions().then((data: any) => {
            getBundleByLocation(data).then((res) => {
                setLoader(false)
                setBannerEsim(res?.data)
            }).catch((error) => {
                console.log(error)
                setLoader(false)
            })
            
        }).catch((error) => {
            console.log("Error getting location:", error);
            getBundleByLocation({ latitude: 0, longitude: 0 }).then((res) => {
                setBannerEsim(res?.data)
                setLoader(false)
            }).catch((error) => {
                console.log(error)
                setLoader(false)
            })
        });

    }, []);
    return (
        <Layout>
            <HomeBanner bannerEsim={bannerEsim} />
            
            {/* <GeoChart /> */}
            <EsimDestination />
            {/* <CountryCoordinates /> */}
            <div className={styles.combitStep}>
                <div className='container'>
                    <div className="conitSlider">
                        <div className='row align-items-center'>
                            <div className='col-lg-6 col-md-12'>
                                <Slider speed={500}
                                    fade={true} asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
                                    <div className={styles.Leftslider}>
                                        <h3>Get started with Commbitz</h3>
                                        <span>Step 1</span>
                                        <ul>
                                            <li><b>Download:</b> Install the Commbitz app from your device's app store.</li>
                                            <li><b>Select your eSIM plan:</b> Browse through available eSIM plans within the app and choose the one that best fits your needs, whether for data, calls, or both.</li>
                                            <li><b>Activate it anywhere, hassle-free: </b>Follow the prompts within the app to activate your chosen eSIM plan. Enjoy seamless connectivity without the need for physical SIM cards, wherever you are in the world.</li>
                                        </ul>
                                    </div>
                                    <div className={styles.Leftslider}>
                                        <h3>Get started with Commbitz</h3>
                                        <span>Step 2</span>
                                        <ul>
                                            <li><b>Download:</b> Install the Commbitz app from your device's app store.</li>
                                            <li><b>Select your eSIM plan:</b> Browse through available eSIM plans within the app and choose the one that best fits your needs, whether for data, calls, or both.</li>
                                            <li><b>Activate it anywhere, hassle-free: </b>Follow the prompts within the app to activate your chosen eSIM plan. Enjoy seamless connectivity without the need for physical SIM cards, wherever you are in the world.</li>
                                        </ul>
                                    </div>
                                    <div className={styles.Leftslider}>
                                        <h3>Get started with Commbitz</h3>
                                        <span>Step 3</span>
                                        <ul>
                                            <li><b>Download:</b> Install the Commbitz app from your device's app store.</li>
                                            <li><b>Select your eSIM plan:</b> Browse through available eSIM plans within the app and choose the one that best fits your needs, whether for data, calls, or both.</li>
                                            <li><b>Activate it anywhere, hassle-free: </b>Follow the prompts within the app to activate your chosen eSIM plan. Enjoy seamless connectivity without the need for physical SIM cards, wherever you are in the world.</li>
                                        </ul>
                                    </div>
                                    <div className={styles.Leftslider}>
                                        <h3>Get started with Commbitz</h3>
                                        <span>Step 1</span>
                                        <ul>
                                            <li><b>Download:</b> Install the Commbitz app from your device's app store.</li>
                                            <li><b>Select your eSIM plan:</b> Browse through available eSIM plans within the app and choose the one that best fits your needs, whether for data, calls, or both.</li>
                                            <li><b>Activate it anywhere, hassle-free: </b>Follow the prompts within the app to activate your chosen eSIM plan. Enjoy seamless connectivity without the need for physical SIM cards, wherever you are in the world.</li>
                                        </ul>
                                    </div>
                                    <div className={styles.Leftslider}>
                                        <h3>Get started with Commbitz</h3>
                                        <span>Step 2</span>
                                        <ul>
                                            <li><b>Download:</b> Install the Commbitz app from your device's app store.</li>
                                            <li><b>Select your eSIM plan:</b> Browse through available eSIM plans within the app and choose the one that best fits your needs, whether for data, calls, or both.</li>
                                            <li><b>Activate it anywhere, hassle-free: </b>Follow the prompts within the app to activate your chosen eSIM plan. Enjoy seamless connectivity without the need for physical SIM cards, wherever you are in the world.</li>
                                        </ul>
                                    </div>
                                    <div className={styles.Leftslider}>
                                        <h3>Get started with Commbitz</h3>
                                        <span>Step 3</span>
                                        <ul>
                                            <li><b>Download:</b> Install the Commbitz app from your device's app store.</li>
                                            <li><b>Select your eSIM plan:</b> Browse through available eSIM plans within the app and choose the one that best fits your needs, whether for data, calls, or both.</li>
                                            <li><b>Activate it anywhere, hassle-free: </b>Follow the prompts within the app to activate your chosen eSIM plan. Enjoy seamless connectivity without the need for physical SIM cards, wherever you are in the world.</li>
                                        </ul>
                                    </div>

                                </Slider>
                            </div>
                            <div className='col-lg-6 col-md-12'>
                                <div className='NavSlide'>

                                    <Slider
                                        asNavFor={nav1}
                                        ref={(slider2) => setNav2(slider2)}
                                        slidesToShow={3}
                                        autoplay={true}

                                        cssEase={'linear'}
                                        speed={500}
                                        infinite={true}
                                        dots={true}
                                        swipeToSlide={true}
                                        centerMode={true}
                                        focusOnSelect={false}
                                    >
                                        <div className={styles.Rightslider}>
                                            <img src={scone} alt="" />
                                        </div>
                                        <div className={styles.Rightslider}>
                                            <img src={sctwo} alt="" />
                                        </div>
                                        <div className={styles.Rightslider}>
                                            <img src={scthree} alt="" />
                                        </div>
                                        <div className={styles.Rightslider}>
                                            <img src={scone} alt="" />
                                        </div>
                                        <div className={styles.Rightslider}>
                                            <img src={sctwo} alt="" />
                                        </div>
                                        <div className={styles.Rightslider}>
                                            <img src={scthree} alt="" />
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className={styles.faqHome}>
                <div className='container'>
                    <h6 className='text-center'>Get started with Commbitz</h6>
                    <h3 className='text-center'>Frequently Asked Questions</h3>
                    <div className={styles.FaqQuestion}>
                        <div className='container'>
                            <div className="accordion" id="accordionExample">
                                {faqs.map((faq) => {
                                    return (
                                        <div className="accordion-item" key={faq._id} onClick={() => setActiveFAQ(activeFAQ === faq._id ? '' : faq._id)}>
                                            <h2 className="accordion-header" id={`heading-${faq._id}`}>
                                                <button
                                                    className={`accordion-button ${activeFAQ === faq._id ? styles.rotate : ''}`}
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#collapse${faq._id}`}
                                                    aria-expanded="false"
                                                    aria-controls={`collapse${faq._id}`}
                                                >
                                                    <p> {faq.question}</p>
                                                </button>
                                            </h2>
                                            <div
                                                id={`collapse${faq._id}`}
                                                className={`accordion-collapse collapse ${activeFAQ === faq._id ? "show" : "hide"}`}
                                                data-bs-parent="#faqAccordion"
                                                aria-labelledby={`heading-${faq._id}`}
                                            >
                                                <div className="accordion-body">
                                                    <div className='row'>
                                                        <div className='col-md-12'>
                                                            <div className={styles.faqaccorright}>
                                                                <div
                                                                    className="text-white"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: mdParser.render(faq.answer as string),
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <BlogSections />
            <PartnerSection />
        </Layout>
    );
};

export default Home;