import * as Yup from 'yup';

const forgetPasswordSchema = Yup.object().shape({
    loginIdentifier: Yup.string().required('Required'),
});

const verifyOTPSchema = Yup.object().shape({
    otp: Yup.string().required('Required'),
});

const resetPasswordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required('New password is required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
      .required('Confirm password is required'),
  });

export {
    forgetPasswordSchema,
    verifyOTPSchema,
    resetPasswordSchema
};