import React from 'react';

import Header from '../header';
import Footer from '../footer';
import { LayoutProps } from './interface';
import { useLocation } from 'react-router-dom';

const Layout = (props: LayoutProps) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const urlToken: any = queryParams.get('token');
    if(urlToken) {
        return (
            <>
                {props.children}
            </>
        );
    } else {
        return (
            <>
                <Header />
                {props.children}
                <Footer />
            </>
        );
    }
   
};

export default Layout;