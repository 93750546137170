import styles from './style.module.css';
import {
    PartnerSectionFive,
    PartnerSectionFour,
    PartnerSectionOne,
    PartnerSectionSeven,
    PartnerSectionSix,
    PartnerSectionThree,
    PartnerSectionTwo
} from "../../assets/images";


const PartnerSection = () => {
    return (
        <section className={styles.partners}>
            <div className='container'>
                <p>Partner with us</p>
                <h3>Collaborate for Success: Become a<br /> Commbitz Partner</h3>
                <ul>
                    <li><img src={PartnerSectionOne} alt="" /></li>
                    <li><img src={PartnerSectionTwo} alt="" /></li>
                    <li><img src={PartnerSectionThree} alt="" /></li>
                    <li><img src={PartnerSectionFour} alt="" /></li>
                    <li><img src={PartnerSectionFive} alt="" /></li>
                    <li><img src={PartnerSectionSix} alt="" /></li>
                    <li><img src={PartnerSectionSeven} alt="" /></li>
                </ul>
            </div>
        </section>
    )
}

export default PartnerSection;