const DATE_FORMAT = "MMM DD, YYYY";

const DEVICE_TYPE = {
    ANDROID: 1,
    IOS: 2,
    WEB: 3,
};
const PAYMENT_METHOD = {
    STRIPEPAY : 1,
    RAZORPAY : 2,
    APPLEPAY : 3
}

const LOGIN = {
    OTPPASS : 1,
    NORMALPASS : 2
}

const BUNDLE_TYPE = {
    LOCAL: 1,
    MULTIVERSE: 2,
};

const THEME = {
    DARK: 'DARK',
    LIGHT: 'LIGHT',
};

export {
    DATE_FORMAT,
    DEVICE_TYPE,
    BUNDLE_TYPE,
    THEME,
    LOGIN,
    PAYMENT_METHOD
};