import { API_URL } from "../../../config";
import { axios } from "../../../lib/axios";
import {BlogResponse, BundlesResponse, CouponResponse} from '../interface'

const getBlogs = async (): Promise<BlogResponse> => {
    return await axios.get(`${API_URL}/blogs?page=1&limit=3&order=-1`);
}

const bundleSearch = async (searchValue:any): Promise<BundlesResponse> => {
    return await axios.get(`${API_URL}/bundle?page=1&limit=1000&search=${searchValue}`);
}
const getCoupons=async ():Promise<CouponResponse>=>{
    return await axios.get(`${API_URL}/coupons`);
}

const getBundleByLocation = async (values:any): Promise<BundlesResponse> => {
    return await axios.get(`${API_URL}/bundle/location?latitude=${values.latitude}&longitude=${values.longitude}`);
}

export {
    getBlogs,
    bundleSearch,
    getCoupons,
    getBundleByLocation
};