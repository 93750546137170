import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import moment from "moment"

import { getBlogs } from "../api";
import { CONSTANTS } from "../../../utils";
import styles from "../styles/home.module.css";


const BlogSections = () => {
    const [blogDetails, setBlogDetails] = useState<any>([]);

    useEffect(() => {
        const getBlogsData = async () => {
            const blogs: any = await getBlogs()
            setBlogDetails(blogs?.data?.blogs)
        }
        getBlogsData();

    }, []);

    return (
        <>
            {blogDetails?.length > 0 &&
                <div className={styles.blogSection}>

                    <div className='container'>
                        <h6>Latest Blogs</h6>
                        <h3>Stay Update with Traveltrek</h3>
                        <div className='row'>
                            {blogDetails.length ?
                                blogDetails.map((blog: any, index: number) =>
                                    <Link className="col-md-4" key={blog._id} to={`/blogdetail/${blog?._id}`}>
                                        <div className={styles.blogInner}>
                                            <img src={blog?.image} alt="" />
                                            <div className={styles.blogDate}>
                                                <span>Featured</span>
                                                <label> {moment(blog?.createdAt).format(CONSTANTS.DATE_FORMAT)}</label>
                                            </div>
                                            <h5> {blog?.title.length > 40 ? `${blog.title.slice(0, 40)}...` : blog.title}</h5>
                                        </div>
                                    </Link>
                                )
                                :
                                <div className='col-md-4'>
                                    <div className={styles.blogInner}>
                                        <h5>Blogs not found</h5>
                                    </div>
                                </div>
                            }
                        </div>
                        {blogDetails?.length>=3 ? 
                        <Link className={styles.Viewbtn} to="/blog">View All</Link>
                        :""}
                    </div>

                </div>
            }
        </>
    )
}

export default BlogSections