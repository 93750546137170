import { API_URL } from "../../../config";
import { axios } from "../../../lib/axios";
import { FAQListResponse } from "../interfaces";

const getFAQS = async (): Promise<FAQListResponse> => {
    return await axios.get(`${API_URL}/faq`);
}

export {
    getFAQS,
};