interface Props {
    searchClass: string;
    placeHolder: string,
    handleSearchSubmit : any
}

const SearchBar = ({ searchClass, placeHolder, handleSearchSubmit }: Props) => {
    return (
        <div className={searchClass}>
            <input type="text" name="searchInput" placeholder={placeHolder} onChange={handleSearchSubmit}/>
        </div>
    )
}

export default SearchBar;