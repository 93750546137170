import styles from './style.module.css';
import NavBar from './NavBar';
import TopNaveBar from './TopNaveBar';

const Header = () => {
  return (
    <header className={styles.headeMain}>
      <TopNaveBar />
      <NavBar />
    </header>
  );
};

export default Header;