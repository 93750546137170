import * as Yup from 'yup';

const ChangePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Required"),
    newPassword: Yup.string().required("Required").min(8, 'Password must be a minimum of 8 characters in length.'),
    confpassword: Yup.string()
      .required("Required")
      .oneOf([Yup.ref('newPassword'), null], 'New passwords do not match')
      .test('password-match', 'Confirm password must match new password', function(value) {
        const { confpassword, newPassword } = this.parent;

        return confpassword === newPassword;
      }),
  });

export {
    ChangePasswordSchema
};